<template>
  <div class="mx-1" v-if="canVerb(resource, 'index')">
    <b-row>
      <b-col align="left">
        <b-button-group
          v-b-toggle:collapse-1
          class="my-2 filter-button-group"
          :title="getDefaultFilterMessage()"
        >
          <span class="when-open">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          <span class="when-closed">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          Filtra
        </b-button-group>
      </b-col>
      <b-col align="right">
        <b-button
          type="button"
          variant="lisaweb"
          size="sm"
          @click="onRefreshContent(filterName)"
          title="Refresh"
          ><b-icon icon="arrow-clockwise" aria-hidden="true"></b-icon
          >Ricarica</b-button
        >
      </b-col>
    </b-row>

    <b-collapse visible id="collapse-1">
      <b-form @submit.prevent="onSearch(filterName)">
        <b-card class="filter">
          <b-row>
            <div class="col-md-3" data-type="report">
              <base-select
                name="code"
                label="Report"
                v-model="filter.byReport.code"
                :options="reportOptions"
              />
            </div>
            <div class="col-md-3" v-if="hasUserAdminRole">
              <base-input
                name="username"
                label="Nome Utente"
                type="text"
                v-model="filter.byUser.username"
                placeholder="Inserisci un nome utente"
              />
            </div>
            <div class="col-md-3">
              <base-datepicker
                name="creazione_da"
                label="Data creazione da"
                v-model="filter.byCalendar.from"
              />
            </div>
            <div class="col-md-3">
              <base-datepicker
                name="creazione_a"
                label="Data creazione a"
                v-model="filter.byCalendar.to"
              />
            </div>
            <div class="form-group col-md-3 align-self-end">
              <b-button
                type="submit"
                variant="lisaweb"
                size="sm"
                v-b-toggle:collapse-1
                >Cerca</b-button
              >
              <b-button
                class="btn-reset"
                type="button"
                variant="lisaweb"
                size="sm"
                @click="onClearFilter(filterName)"
                >Reset</b-button
              >
            </div>
          </b-row>
        </b-card>
      </b-form>
    </b-collapse>
    <generated-reports
      :fields="fields"
      :repository="repository"
      :resource="resource"
      :filterOn="{
        byRelations: ['byReport', 'byDocument'],
      }"
      :filterName="filterName"
      :ref="tableRef"
      descending
      noActions
      sortField="created_at"
      @report="downloadReport"
    ></generated-reports>
  </div>
  <div v-else class="mt-3">
    <b-icon icon="info-circle" scale="1.00"></b-icon>
    Non hai i permessi
  </div>
</template>

<script>
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import DocumentsMixin from "@/mixins/DocumentsMixin";
import StorageGetterMixin from "@/mixins/StorageGetterMixin";
import GeneratedReports from "@/components/tables/GeneratedReports.vue";
import { mapActions } from "vuex";
import BaseInput from "@/components/form/BaseInput";
import BaseSelect from "@/components/form/BaseSelect";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import { toLocaleDate } from "@/utils/dates";
import { isAdmin, canVerb } from "@/utils/permissions";

export default {
  mixins: [CustomFiltersMixin, DocumentsMixin, StorageGetterMixin],
  data() {
    return {
      repository: "generated_report",
      resource: "generated_reports",
      // reportsAvailable: ["AGGRECLI"],
      tableRef: "GeneratedReportFilter",
      filterName: "filterGeneratedReport",
      filter: this.initFilter(),
      fields: [
        {
          key: "report.title",
          label: this.getDictionary("formatted_title", "generated_report"),
          sortable: true,
          sortKey: "byReport.title",
        },
        {
          key: "report.code",
          label: this.getDictionary("code", "generated_report"),
          sortable: true,
          sortKey: "byReport.code",
        },
        {
          key: "format",
          label: this.getDictionary("format", "generated_report"),
        },
        {
          key: "status_report.text",
          label: this.getDictionary("status_report", "generated_report"),
        },
        {
          key: "is_ready.text",
          label: this.getDictionary("is_ready", "generated_report"),
        },
        {
          key: "user",
          label: this.getDictionary("username", "user"),
          sortable: true,
          sortKey: "byUser.username",
        },
        {
          key: "created_date",
          label: this.getDictionary("created_at"),
          sortable: true,
          formatter: (value) => toLocaleDate(value, "DD/MM/YYYY HH:mm:ss"),
          sortKey: "created_at",
        },
        {
          key: "download",
          label: this.getDictionary("download", "generated_report"),
          tdClass: "text-center",
          thStyle: {
            width: "125px",
          },
        },
      ],
      isUserAdmin: false,
      reports: [],
      reportOptions: [],
      // reportOptions: [
      //   { text: "ESPORTAZIONE POLIZZE ASSICURATIVE", value: "EXPOINPO" },
      //   { text: "ESPORTAZIONE SINISTRI", value: "EXPOCLAI" },
      //   { text: "ESPORTAZIONE ANAGRAFICHE", value: "EXPOREGI" },
      // ],
    };
  },
  components: {
    GeneratedReports,
    BaseInput,
    BaseDatepicker,
    BaseSelect,
  },
  created() {
    this.isUserAdmin = isAdmin();
    /* */
    const path = "auth/";
    const resources = [];
    if (this.canVerb("reports", "index") && !this.hasLoadedResource("reports"))
      resources.push(
        this.$store
          .dispatch(`${path}reports`)
          .then(() => {})
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    if (resources.length) {
      this.getResources(resources)
        .then(() => {
          this.getReports();
        })
        .catch((error) => {
          console.log("One or more promises crashed... :|");
          this.onFailure(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      this.getReports();
      this.isLoading = false;
    }
    /* */
  },
  methods: {
    isAdmin,
    canVerb,
    downloadReport(document) {
      this.downloadDocument(document);
    },
    initFilter() {
      let init = {
        byAttribute: {
          format: null,
        },
        byReport: {
          title: null,
          code: null, // TODO: to restrict viewable reports, set 'em here! ["AGGRECLI"],
        },
        byCalendar: {
          from: null,
          to: null,
        },
        byUser: {
          id: null,
          username: null,
        },
      };
      return init;
    },
    getReports() {
      this.reports = this.$store.state.auth.reports;
      // this.reports = this.$store.state.auth.reports.filter((e) =>
      //   this.reportsAvailable.includes(e.code)
      // );
      // this.reportOptions = [
      //   ...this.reportOptions,
      //   ...this.reports.map((e) => {
      //     return {
      //       text: e.title,
      //       value: e.code,
      //     };
      //   }),
      // ];
      // this.reportOptions.sort((a, b) => (a.text > b.text) - (a.text < b.text));
      if (this.reports.length <= 0) return;
      this.reportOptions = this.reports.map((e) => {
        return {
          text: `${e.title}
          ↪ ${e.code}`,
          value: e.code,
        };
      });
      this.reportOptions.sort((a, b) => (a.text > b.text) - (a.text < b.text));
      // this.reportOptions.sort((a, b) => {
      //   const textA = a.text.replace(/\[.*?\]\s*-\s*/, "");
      //   const textB = b.text.replace(/\[.*?\]\s*-\s*/, "");
      //   return textA > textB ? 1 : textA < textB ? -1 : 0;
      // });
    },
    onRefreshContent(name) {
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });
      this.$refs[this.tableRef].fetch();
    },
    onSearch(name) {
      if (!this.isUserAdmin)
        this.filter.byUser.id = this.$store.state.auth?.user?.id;
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });
      this.removePaginationByName(name);
      this.$refs[this.tableRef].currentPage = 1;
      this.$refs[this.tableRef].fetch();
    },
    onClearFilter(name) {
      this.resetFilter();
      this.resetPagination();
      this.removeFilterByName(name);
      this.removePaginationByName(name);
      this.removeSortByName(name);
      this.$refs[this.tableRef].sortBy = "";
      this.$refs[this.tableRef].sortDesc = false;
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("pagination", { removePaginationByName: "removeByName" }),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
    onSaveFilter(name, criteria) {
      this.saveFilterByName({ name, criteria });
    },
  },
  computed: {
    hasUserAdminRole() {
      return this.isUserAdmin;
    },
  },
};
</script>
<style lang="scss" scoped>
:deep(div[data-type="report"] .multiselect) {
  height: auto;
}
:deep(div[data-type="report"] .multiselect .multiselect__option span) {
  white-space: pre-line;
}
:deep(div[data-type="report"] .multiselect .multiselect__single) {
  white-space: pre-line;
}
</style>
