<template>
  <div class="mx-1">
    <div class="fields-wrapper" data-type="reports">
      <div class="search-wrapper">
        <b-input-group size="sm" class="search-bar">
          <b-input-group-prepend is-text class="search-bar-icon">
            <b-icon icon="search"></b-icon>
          </b-input-group-prepend>

          <b-form-input
            autocomplete="off"
            type="search"
            placeholder="Digita un report da ricercare"
            size="sm"
            v-model="searchedTerms"
            @keyup="filterData"
          >
          </b-form-input>
        </b-input-group>
      </div>
    </div>
    <div
      class="no-res"
      v-if="
        accountingReports.length === 0 &&
        homeReports.length === 0 &&
        insuranceAncillariesReports.length === 0 &&
        walletReports.length === 0
      "
    >
      Non ci sono report che corrispondono alla ricerca
    </div>
    <div class="reports-wrapper">
      <b-card
        header="Report Homepage"
        class="my-2"
        v-if="hasAccessToHome && homeReports.length > 0"
      >
        <b-card-text>
          <generated-reports
            ref="homeReportsRef"
            :reports-available="homeReports"
          />
        </b-card-text>
      </b-card>
      <b-card
        header="Report Portafoglio"
        class="my-2"
        v-if="hasAccessToWallet && walletReports.length > 0"
      >
        <b-card-text>
          <generated-reports
            ref="walletReportsRef"
            :reports-available="walletReports"
          />
        </b-card-text>
      </b-card>
      <b-card
        header="Report Titoli"
        class="my-2"
        v-if="
          hasAccessToInsuranceAncillary &&
          insuranceAncillariesReports.length > 0
        "
      >
        <b-card-text>
          <generated-reports
            ref="insuranceAncillariesReportsRef"
            :reports-available="insuranceAncillariesReports"
          />
        </b-card-text>
      </b-card>
      <b-card
        header="Report Contabilità"
        class="my-2"
        v-if="hasAccessToAccounting && accountingReports.length > 0"
      >
        <b-card-text>
          <generated-reports
            ref="accountingReportsRef"
            :reports-available="accountingReports"
          />
        </b-card-text>
      </b-card>
    </div>
  </div>
</template>

<script>
import GeneratedReports from "@/components/GeneratedReports";
import { canModule } from "@/utils/permissions";

export default {
  data() {
    return {
      reports: null,
      filteredHomeReports: [],
      searchedTerms: null,
      initialHomeReports: [],
      initialWalletReports: [],
      initialInsuranceAncillariesReports: [],
      initialAccountingReports: [],
      walletReports: [
        "AGGRECLI",
        "MONUPROD",
        "REPONUCL",
        "POLCLIGRU",
        "PORTATTI",
        "SINPRECLI" /* "SCACLIGRU",  "RUBRICLI" */,
      ], // module.PFOLIO.REPG
      insuranceAncillariesReports: [
        "TITULTIGI",
        "REPOLEX",
        "RECATIIN",
        "REDETIIN",
        "TITINCACLI",
      ], // module.TIT.REPO
      accountingReports: [
        "INCAPAGA",
        "COMINCA",
        "INCACOMP",
        "INCACOMP2",
        "INCACLIGRU",
        "INCACOL",
        "INCARAM",
        "INCAPROD" /*  "RENPROD", "RENCOMP", "INCARAMTI" */,
      ], // module.CONT.REPO
      homeReports: ["REPAPP", "REPATT"], // module.HOME.REPG
    };
  },
  methods: {
    canModule,
    filterData(event) {
      const inputValue = event.target.value;
      console.log("inputVal: ", inputValue);

      const filteredHomeReports = this.reports
        .filter((report) => report.section === "home")
        .filter(
          (option) =>
            option.text.toLowerCase().includes(inputValue.toLowerCase()) ||
            option.code.toLowerCase().includes(inputValue.toLowerCase())
        )
        .map((opt) => opt.code);
      this.homeReports = filteredHomeReports.filter((report) =>
        this.initialHomeReports.map((rep) => report === rep)
      );

      const filteredWalletReports = this.reports
        .filter((report) => report.section === "wallet")
        .filter(
          (option) =>
            option.text.toLowerCase().includes(inputValue.toLowerCase()) ||
            option.code.toLowerCase().includes(inputValue.toLowerCase())
        )
        .map((opt) => opt.code);
      this.walletReports = filteredWalletReports.filter((report) =>
        this.initialWalletReports.map((rep) => report === rep)
      );

      const filteredInsuranceAncillariesReports = this.reports
        .filter((report) => report.section === "insurance_ancillaries")
        .filter(
          (option) =>
            option.text.toLowerCase().includes(inputValue.toLowerCase()) ||
            option.code.toLowerCase().includes(inputValue.toLowerCase())
        )
        .map((opt) => opt.code);
      this.insuranceAncillariesReports =
        filteredInsuranceAncillariesReports.filter((report) =>
          this.initialInsuranceAncillariesReports.map((rep) => report === rep)
        );

      const filteredAccountingReports = this.reports
        .filter((report) => report.section === "accounting")
        .filter(
          (option) =>
            option.text.toLowerCase().includes(inputValue.toLowerCase()) ||
            option.code.toLowerCase().includes(inputValue.toLowerCase())
        )
        .map((opt) => opt.code);
      this.accountingReports = filteredAccountingReports.filter((report) =>
        this.initialAccountingReports.map((rep) => report === rep)
      );

      console.log("filteredHomeReports: ", this.filteredHomeReports);
      console.log("homeReports: ", this.homeReports);
    },
  },
  mounted() {
    this.initialHomeReports = Object.values(
      this.$refs.homeReportsRef.reportOptions
    ).map((option) => {
      return {
        text: option.text,
        code: option.value,
        section: "home",
      };
    });
    this.initialWalletReports = Object.values(
      this.$refs.walletReportsRef.reportOptions
    ).map((option) => {
      return {
        text: option.text,
        code: option.value,
        section: "wallet",
      };
    });
    this.initialInsuranceAcillariesReports = Object.values(
      this.$refs.insuranceAncillariesReportsRef.reportOptions
    ).map((option) => {
      return {
        text: option.text,
        code: option.value,
        section: "insurance_ancillaries",
      };
    });
    this.initialAccountingReports = Object.values(
      this.$refs.accountingReportsRef.reportOptions
    ).map((option) => {
      return {
        text: option.text,
        code: option.value,
        section: "accounting",
      };
    });
    this.reports = [
      ...this.initialHomeReports,
      ...this.initialWalletReports,
      ...this.initialInsuranceAcillariesReports,
      ...this.initialAccountingReports,
    ];

    console.log("reports", this.reports);
  },
  components: { GeneratedReports },
  computed: {
    hasAccessToWallet() {
      return canModule("module.PFOLIO");
    },
    hasAccessToInsuranceAncillary() {
      return canModule("module.TIT");
    },
    hasAccessToAccounting() {
      return canModule("module.CONT");
    },
    hasAccessToHome() {
      return canModule("module.HOME");
    },
  },
};
</script>

<style>
.search-bar {
  color: #9c9c9c;
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}

.search-wrapper {
  margin-top: 1rem;
  position: sticky;
  top: 0;
  padding: 6px;
  border-radius: 8px;
  border: 1px solid #eaeaea;
  background: #d5d5d5;
  z-index: 1;
}

.data-sources {
  display: grid;
  grid-template-columns: 140px 1fr;
  grid-template-rows: 50px 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  padding: 0 0 0 20px;

  .section-label {
    grid-area: 1 / 1 / 2 / 2;
  }

  .flex-container {
    grid-area: 2 / 1 / 3 / 2;
  }

  .fields-wrapper {
    background: #d5d5d5;
    grid-area: 1 / 2 / 3 / 3;
    overflow-y: auto;
    position: relative;

    &:after {
      position: sticky;
      display: block;
      width: 100%;
      height: 60px;
      left: 0;
      bottom: -10px;
      content: "";
      pointer-events: none;
      touch-action: none;
      cursor: default;
      user-select: none;
      -webkit-tap-highlight-color: transparent;
      z-index: 1;
      background: linear-gradient(0deg, #d5d5d5, hsla(0, 0%, 100%, 0));
    }
  }
}

.no-res {
  margin-top: 10px;
  padding: 0;
  text-align: center;
}

/* clears the ‘X’ from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
/* Search */
</style>
