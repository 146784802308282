var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mx-1"},[_c('div',{staticClass:"fields-wrapper",attrs:{"data-type":"reports"}},[_c('div',{staticClass:"search-wrapper"},[_c('b-input-group',{staticClass:"search-bar",attrs:{"size":"sm"}},[_c('b-input-group-prepend',{staticClass:"search-bar-icon",attrs:{"is-text":""}},[_c('b-icon',{attrs:{"icon":"search"}})],1),_vm._v(" "),_c('b-form-input',{attrs:{"autocomplete":"off","type":"search","placeholder":"Digita un report da ricercare","size":"sm"},on:{"keyup":_vm.filterData},model:{value:(_vm.searchedTerms),callback:function ($$v) {_vm.searchedTerms=$$v},expression:"searchedTerms"}})],1)],1)]),_vm._v(" "),(
      _vm.accountingReports.length === 0 &&
      _vm.homeReports.length === 0 &&
      _vm.insuranceAncillariesReports.length === 0 &&
      _vm.walletReports.length === 0
    )?_c('div',{staticClass:"no-res"},[_vm._v("\n    Non ci sono report che corrispondono alla ricerca\n  ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"reports-wrapper"},[(_vm.hasAccessToHome && _vm.homeReports.length > 0)?_c('b-card',{staticClass:"my-2",attrs:{"header":"Report Homepage"}},[_c('b-card-text',[_c('generated-reports',{ref:"homeReportsRef",attrs:{"reports-available":_vm.homeReports}})],1)],1):_vm._e(),_vm._v(" "),(_vm.hasAccessToWallet && _vm.walletReports.length > 0)?_c('b-card',{staticClass:"my-2",attrs:{"header":"Report Portafoglio"}},[_c('b-card-text',[_c('generated-reports',{ref:"walletReportsRef",attrs:{"reports-available":_vm.walletReports}})],1)],1):_vm._e(),_vm._v(" "),(
        _vm.hasAccessToInsuranceAncillary &&
        _vm.insuranceAncillariesReports.length > 0
      )?_c('b-card',{staticClass:"my-2",attrs:{"header":"Report Titoli"}},[_c('b-card-text',[_c('generated-reports',{ref:"insuranceAncillariesReportsRef",attrs:{"reports-available":_vm.insuranceAncillariesReports}})],1)],1):_vm._e(),_vm._v(" "),(_vm.hasAccessToAccounting && _vm.accountingReports.length > 0)?_c('b-card',{staticClass:"my-2",attrs:{"header":"Report Contabilità"}},[_c('b-card-text',[_c('generated-reports',{ref:"accountingReportsRef",attrs:{"reports-available":_vm.accountingReports}})],1)],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }